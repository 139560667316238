/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

html {
  font-family: "Sofia Sans", sans-serif;
  font-optical-sizing: auto;
  color: var(--primary-color);
  scroll-behavior: smooth;
}

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --black-color: hsl(220, 24%, 12%);
  --black-color-light: hsl(220, 24%, 15%);
  --black-color-lighten: hsl(220, 20%, 18%);
  --white-color: #fff;
  --body-color: hsl(220, 100%, 97%);
  --primary-color: #333;
  --secondary-color: #287af4;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Montserrat", sans-serif;
  --normal-font-size: 0.938rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--white-color);
}

ul {
  list-style: none;
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/
}

a {
  text-decoration: none;
}

/*=============== HEADER ===============*/
.header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: whitesmoke;
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
  z-index: var(--z-fixed);
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
}

.nav__logo,
.nav__burger,
.nav__close {
  color: var(--black-color);
}

.ghs__logo {
  width: 200px;
  height: 100px;
}

.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30vw;
}

.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  font-weight: var(--font-semi-bold);
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/
}

.nav__logo i {
  font-weight: initial;
  font-size: 1.25rem;
}

.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
}

.nav__burger,
.nav__close {
  position: absolute;
  width: max-content;
  height: max-content;
  inset: 0;
  margin: auto;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}

.nav__close {
  opacity: 0;
}

.solutions__search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.solutions__top {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.propos {
  display: flex;
  justify-content: space-between;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1118px) {
  .nav__menu {
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0;
  }
  .nav__list {
    background-color: var(--white-color);
    padding-top: 1rem;
  }
  .propos {
    flex-direction: column-reverse;
  }
}

.nav__link {
  padding: 1.25rem 1.5rem;
}

.nav__link,
.catalogue {
  color: var(--black-color);
  font-weight: var(--font-semi-bold);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.nav__link:hover,
.catalogue:hover {
  background-color: var(--white-color);
}

/* Show menu */
.show-menu {
  opacity: 1;
  top: 3.5rem;
  pointer-events: initial;
}

/* Show icon */
.show-icon .nav__burger {
  opacity: 0;
  transform: rotate(90deg);
}
.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__item {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform 0.4s;
}

.dropdown__link,
.dropdown__sublink {
  padding: 0.7rem 1rem;
  color: var(--white-color);
  background-color: var(--black-color);
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  font-weight: var(--font-semi-bold);
  transition: background-color 0.3s;
}

.dropdown__link i,
.dropdown__sublink i {
  font-size: 1rem;
  font-weight: initial;
}

.dropdown__link:hover,
.dropdown__sublink:hover {
  background-color: var(--white-color);
  color: var(--black-color);
}

.dropdown__menu,
.dropdown__submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu,
.dropdown__subitem:hover > .dropdown__submenu {
  max-height: 1000px;
  transition: max-height 0.4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
  transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
  margin-left: auto;
}

.dropdown__sublink {
  background-color: var(--black-color);
}

.catalogue {
  border: none;
  background-color: transparent;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container {
    margin-inline: 1rem;
  }

  .nav__link {
    padding-inline: 1rem;
  }

  .ghs__logo {
    width: 300px;
    height: 80px;
  }

  .nav {
    gap: 3rem;
  }
}

/* For large devices */
@media screen and (min-width: 1118px) {
  .container {
    margin-inline: auto;
  }

  .nav {
    height: calc(var(--header-height) + 2rem);
    display: flex;
    justify-content: space-between;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    height: 100%;
    display: flex;
    column-gap: 2rem;
  }
  .nav__link {
    height: 100%;
    padding: 0;
    justify-content: initial;
    column-gap: 0.25rem;
  }
  .nav__link:hover {
    background-color: transparent;
  }

  .dropdown__item,
  .dropdown__subitem {
    position: relative;
  }

  .dropdown__menu,
  .dropdown__submenu {
    max-height: initial;
    overflow: initial;
    position: absolute;
    left: 0;
    top: 6rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s, top 0.3s;
  }

  .dropdown__link,
  .dropdown__sublink {
    font-size: 0.8rem;
    padding-inline: 1rem;
  }

  .dropdown__subitem .dropdown__link {
    padding-inline: 1rem;
  }

  .dropdown__submenu {
    position: absolute;
    left: 100%;
    top: 0.5rem;
  }

  /* Show dropdown menu */
  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    top: 5.5rem;
    pointer-events: initial;
    transition: top 0.3s;
  }

  /* Show dropdown submenu */
  .dropdown__subitem:hover > .dropdown__submenu {
    opacity: 1;
    top: 0;
    pointer-events: initial;
    transition: top 0.3s;
  }

  .ghs__logo {
    width: 300px;
    height: 200px;
  }

  .catalogue {
    border: 2px solid #000;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    font-weight: var(--font-semi-bold);
    font-size: 1rem;
  }

  .catalogue:hover {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
    transition: 0.5s;
  }

  .solutions__search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }
}

section {
  min-height: 100vh;
  background-color: var(--white-color);
  padding: 4rem;
}

.hero {
  display: flex;
  align-items: center;
}

.hero__top {
  display: flex;
  justify-content: space-between;
  margin: 5rem;
}

.hero__figure {
  max-width: 900px;
  position: relative;
  left: 100px;
  display: flex;
  justify-content: end;
}

.hero__img {
  width: 100%;
}

.texts {
  max-width: 700px;
  line-height: 1.5rem;
  padding-top: 3rem;
  padding: 1rem;
  background-color: rgba(90, 90, 90, 0.4);
}

.texts__p {
  color: whitesmoke;
  font-size: 1.1rem;
}

.texts__h1 {
  font-size: 2.5rem;
  margin: 1rem 0rem;
  color: var(--white-color);
}

.solutions__link {
  padding: 0.5rem 1rem;
  border: 2px solid var(--black-color);
  border-radius: 15px;
  color: var(--white-color);
  border-color: var(--secondary-color);
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
  background-color: var(--black-color);
}

.solutions__link:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.products__h {
  text-align: center;
  font-size: 3rem;
  color: var(--secondary-color);
}

.ghs__text {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 3rem;
  color: #333;
}

.G {
  color: var(--secondary-color);
}

.hero__search {
  margin-top: 3rem;
  background: lightcyan;
}

.productsList,
.solutionsList {
  background-color: white;
}

.productsList {
  padding: 2rem;
  overflow-x: hidden;
}

.solutionsList {
  padding: 1rem 2rem 2rem 2rem;
}

.socialMedia {
  font-size: 2rem;
}

.hero-section {
  padding: 30px 0;
}

.text-side {
  padding: 20px;
}

.img-side img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {
  /* Adjust layout for smaller screens */
  .text-side,
  .img-side {
    width: 100%;
    text-align: center;
    padding: 20px;
  }
  .category-card {
    width: 100%;
  }

  .contact-img {
    width: 20px;
  }

  .contact-top {
    display: flex;
    flex-direction: column;
  }
  .contact-phone {
    margin-right: 10rem;
  }
}

.category-card {
  background-color: red;

  margin-bottom: 20px;
}

.category-card img {
  max-width: 100%;
  height: 230px;
}

.category-item {
  opacity: 0;
  transform: translateY(100px); /* Initial position off-screen */
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.category-item.active {
  opacity: 1;
  transform: translateY(0); /* Slide in animation */
}

.contact-img {
  width: 80px;
  padding: 5px;
}

.contact-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
}

.carousel-control-prev-icon:after,
.carousel-control-next-icon:after {
  content: "";
}

/* Responsive Pagination */
@media (max-width: 768px) {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .pagination .page-item {
    flex: 1 1 30px;
    margin: 2px;
  }
  .pagination .page-item .page-link {
    padding: 0.5rem 0.75rem;
  }
}
